import React from "react"
import "../styles/Footer.css"

const Footer = props => (
    <div className= "footer-body">
        <p>
            © Ethan Lam
        </p>
        <p>
            <a href="https://instagram.com/ethannomiddlenamelam">
                follow me on instagram follow me on instagram follow me on instagram follow me on instagram follow me on instagram follow me on instagram 
            </a>
        </p>
    </div>
)
export default Footer;